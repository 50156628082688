import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import database from "@/database";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import axios from "axios";

Vue.use(Vuex);
VuexORM.use(VuexORMAxios, { axios });

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {
    queueCount: 0,
    userlevel: Infinity,
    username: null,
    mobile: false,
    filiale: null,
    bestellungConfig: {
      savedsearch: "",
      currentid: "",
      lastid: "",
      lastidshort: "",
      datum: new Date(),
      datecollection: "",
      store: "",
      storecollection: "",
      customer: "",
      ordermode: "",
      rendermode: "",
      fish: "",
      consumable: "",
      bestellnummer: "",
      bestellnummerkurz: "",
      quicksave: null,
      listmode: "orders",
      laststatus: null,
      lagernummergesamt: ""
    },
    einkaufConfig: {
      lastid: "",
      lastidshort: "",
      datum: "",
      provider: "",
      ordermode: "",
      rendermode: "",
      fish: "",
      consumable: "",
      einkaufnummer: "",
      quicksave: null,
      listmode: "purchases"
    }
  },
  mutations: {
    queueCount(state, queueCount) {
      state.queueCount = queueCount;
    },
    userlevel(state, userlevel) {
      state.userlevel = userlevel;
    },
    username(state, username) {
      state.username = username;
    },
    filiale(state, filiale) {
      state.filiale = filiale;
    },
    mobile(state, mobile) {
      state.mobile = mobile;
    },
    bestellungConfig(state, payload) {
      state.bestellungConfig[payload.key] = payload.value;
    },
    einkaufConfig(state, payload) {
      state.einkaufConfig[payload.key] = payload.value;
    }
  },
  actions: {
    filiale: ({ commit }, stammFiliale) => {
      commit("filiale", stammFiliale);
    },
    userlevel: ({ commit }, level) => {
      commit("userlevel", level);
    },
    queueCount: ({ commit }, count) => {
      commit("queueCount", count);
    },
    username: ({ commit }, name) => {
      commit("username", name);
    },
    mobile: ({ commit }, isMobile) => {
      commit("mobile", isMobile);
    },
    bestellungConfig: ({ commit }, payload) => {
      commit("bestellungConfig", payload);
    },
    einkaufConfig: ({ commit }, payload) => {
      commit("einkaufConfig", payload);
    }
  },
  modules: {}
});
