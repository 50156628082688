import { VBtn, VDataTable, VIcon, VTextField } from "vuetify/lib";
import Kunden from "@/models/kunden";
//import { v4 as uuidv4 } from "uuid";
import "../../overviewTables.scss";
import {
  updateSortValue,
  updateSortValuesFromQuery,
  highlight
} from "@/utils/helpers";

export default {
  props: ["target"],
  data() {
    return {
      search: "",
      sortBy: "nachname",
      sortDesc: false
    };
  },
  mounted() {
    if (this.$store.state.bestellungConfig.savedsearch.length) {
      this.search = this.$store.state.bestellungConfig.savedsearch;
    }
    if (this.$store.state.bestellungConfig.customer) {
      const suchKunde = Kunden.find(
        this.$store.state.bestellungConfig.customer
      );
      if (suchKunde && suchKunde.nachname) {
        this.search = suchKunde.nachname;
      }
    }

    updateSortValuesFromQuery(this);
  },
  watch: {
    search(value) {
      this.updateSortValue(this, "search", value);
    }
  },
  beforeDestroy() {
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name === "kundenEdit") {
      const newQuery = Object.assign({}, currentRoute.query, {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        search: this.search
      });
      this.$router.replace({ name: "kundenEdit", query: newQuery });
    }
  },
  computed: {
    kunden() {
      const alleKunden = Kunden.all();
      for (let i = 0; i < alleKunden.length; i++) {
        if (alleKunden[i].mobil) {
          alleKunden[i].mobil = alleKunden[i].mobil.replace(/[^0-9]/gi, "");
        }
        if (alleKunden[i].telefon) {
          alleKunden[i].telefon = alleKunden[i].telefon.replace(/[^0-9]/gi, "");
        }
        if (alleKunden[i].telefon && alleKunden[i].mobil) {
          alleKunden[i].mobil = [
            alleKunden[i].telefon,
            alleKunden[i].mobil
          ].join(", ");
        }
        if (alleKunden[i].telefon && !alleKunden[i].mobil) {
          alleKunden[i].mobil = alleKunden[i].telefon;
        }
      }
      return alleKunden;
    },
    headers() {
      return [
        { text: this.$t("label.lastname"), value: "nachname" },
        { text: this.$t("label.firstname"), value: "vorname" },
        { text: this.$t("label.companyname"), value: "firmenname" },
        { text: this.$t("label.mobile"), value: "mobil" },
        { text: "" }
      ];
    }
  },
  methods: {
    editCustomer(id) {
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: id
      });
      this.$store.commit("bestellungConfig", {
        key: "savedsearch",
        value: this.search
      });
      this.$emit("customer-edit");
    },
    clearSelected() {
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: ""
      });
    },
    clearSearch() {
      this.search = "";
      this.clearSelected();
    },
    customerChosen(id) {
      if (this.target != "addorder") {
        this.$store.commit("bestellungConfig", {
          key: "customer",
          value: id
        });
        this.$emit("customer-chosen");
        switch (this.target) {
          case "filter":
            this.$emit("customer-close");
            break;
        }
      }
    },
    highlight,
    updateSortValue
  },
  render() {
    const scopedSlots = {
      item: item => {
        return (
          <tr
            class={
              item.item.id == this.$store.state.bestellungConfig.customer
                ? "selectedTR"
                : ""
            }
          >
            <td onClick={this.customerChosen.bind(this, item.item.id)}>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.nachname,
                  this.search
                )}</span>`}
              ></span>
            </td>
            <td onClick={this.customerChosen.bind(this, item.item.id)}>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.vorname,
                  this.search
                )}</span>`}
              ></span>
            </td>
            <td onClick={this.customerChosen.bind(this, item.item.id)}>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.firmenname,
                  this.search
                )}</span>`}
              ></span>
            </td>
            <td onClick={this.customerChosen.bind(this, item.item.id)}>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.mobil,
                  this.search
                )}</span>`}
              ></span>
            </td>
            <td align="right">
              <VBtn
                fab
                outlined
                color="orange"
                x-small
                onClick={() => this.editCustomer(item.item.id)}
              >
                <VIcon color="orange" small>
                  mdi-pencil
                </VIcon>
              </VBtn>
            </td>
          </tr>
        );
      },
      top: () => {
        return (
          <VTextField
            hide-details
            solo
            clearable
            append-icon="mdi-magnify"
            v-model={this.search}
            class="customerHeadSearch pa-0 ma-0"
          ></VTextField>
        );
      }
    };
    return (
      <VDataTable
        itemKey="id"
        items={this.kunden}
        headers={this.headers}
        search={this.search}
        scopedSlots={scopedSlots}
        height="calc(100vh - 185px)"
        footer-props={{
          "items-per-page-options": [10, 20]
        }}
        sort-by="nachname"
        fixed-header
        options={{ itemsPerPage: 10 }}
        sortBy={this.sortBy}
        sortDesc={this.sortDesc}
        on={{
          "update:sort-by": value =>
            this.updateSortValue(this, "sortBy", value),
          "update:sort-desc": value =>
            this.updateSortValue(this, "sortDesc", value)
        }}
      />
    );
  }
};
